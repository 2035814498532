import { Spin } from 'antd';
import styles from './index.less';
import IglooIcon from '@/assets/igloo.gif';

const antIcon = <img src={IglooIcon} className={styles.loadingIcon} alt="" />;

const CustomSpinner = () => (
  <Spin
    indicator={antIcon}
    style={{
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fitContent',
    }}
    data-testid="spinner"
  />
);

export default CustomSpinner;
